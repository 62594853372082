<template>
    <div class="page">
        <el-backtop><i class="el-icon-caret-top"></i></el-backtop>
        <img src="../../assets/images/cliu/cliu1.png" alt="" srcset="" class="imgs1">
        <img src="../../assets/images/cliu/cliu2.png" alt="" srcset="" class="imgs2">
        <img src="../../assets/images/cliu/cliu3.png" alt="" srcset="" class="imgs3">
        <img src="../../assets/images/cliu/cliu4.png" alt="" srcset="" class="imgs4">
        <img src="../../assets/images/wenli-3.png" alt="" srcset="" class="wl3">
    </div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    created(){
    },
    methods: {
    }
    
}
</script>
<style lang="scss" scoped>
.page{
    background: #F8F9FA;
    position: relative;
    // display: inline-block;
    // width: 100%;
    .imgs1{
        width: 1509px;
        height: 686.41px;
        margin-top: 104px;
        margin-left: 138px;
        display: block;
        position: relative;
        z-index: 1;
    }
    .imgs2{
        width: 1679px;
        height: 909px;
        margin-top: 201px;
        margin-left: 138px;
        display: block;
        position: relative;
        z-index: 1;
    }
    .imgs3{
        width: 1644px;
        height: 994px;
        margin-top: 136px;
        margin-left: 138px;
        display: block;
        position: relative;
        z-index: 1;
    }
    .imgs4{
        width: 1920px;
        height: 1083px;
        margin-top: 193px;
        display: block;
        position: relative;
        z-index: 1;
    }
    .wl3{
        width: 1146px;
        height: 1043px;
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>